import React from "react";
import {Redirect, BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";

import AddToCalender from "./AddToCalender";
import {Consult} from "./components/Consult";
import {MockConsult} from "./components/Mock";

const Selector = () => (
    <ul>
        {
            Array.from(Array(20).keys()).map(
                i => <li key={i}><Link to={`/${i + 1}`}>Test User {i + 1}</Link></li>
            )
        }
    </ul>
)

export default function App() {
    return (
        <Router>
            <div className="container">
                <Switch>
                    <Route exact path="/cal/:consult_token_id">
                        <AddToCalender/>
                    </Route>
                    <Route path="/mock/:consult_token_id">
                        <MockConsult/>
                    </Route>
                    <Route path="/:consult_token_id">
                        <Consult/>
                    </Route>
                    <Route path="/" exact>
                        <Selector/>
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </div>
        </Router>
    );
}

