import React from "react";

import {useSelector} from "react-redux";
import {Participant} from "./Participant";

export default () => {

    const participants = useSelector(store => store.audioVideo.participants);

    return Object.keys(participants).map((participantID) =>
        <Participant
            participant={participants[participantID]}
            key={participantID}
        />)
}