import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch, useSelector} from "react-redux";
import {unmuteRequest} from "../redux/booking/actions";

const RequestUnmuteDialog = () => {
    const device = useSelector((state) => state.bookingDetails?.unmuteFeed);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(unmuteRequest(null))
    };

    return (
        <Dialog open={!!device} onClick={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Request received from Facilitator</DialogTitle>
            <DialogContent>
                <p>Please turn on your {device}</p>
            </DialogContent>
        </Dialog>
    );
};

export default RequestUnmuteDialog;
