import React from 'react';
import {ParticipantLabel, ParticipantLive} from "../../layout/layout";
import {useSelector} from "react-redux";

const ParticipantHeader = ({participantID}) => {
    const participants = useSelector(store => store.audioVideo.participants);
    const hasMic = useSelector(state => {
        if (participantID === 'local')
            return state.audioVideo.localState?.hasMic
        return false
    });

    return (
        <ParticipantLabel>
            {participantID === 'local' ? 'You' : participants[participantID]?.name}
            {hasMic && <ParticipantLive>&nbsp; - YOU ARE LIVE</ParticipantLive>}
        </ParticipantLabel>
    );
};

export default ParticipantHeader;