import React from 'react';
import ScreenShare from '@material-ui/icons/ScreenShare';
import {useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";

const ScreenShareIndicator = ({id, onClick}) => {
    const videos = useSelector(store => {
        if (id === 'local')
            return [store.audioVideo.localTracks.screen];
        return store.audioVideo.remoteVideoTracks[id];
    });

    const isScreenShared = videos && videos.filter(track => {
        if (!track) return false;
        if (track?.type === 'screen') return true;
        let trackName = track.trackName ? track.trackName : track.name;
        return trackName.indexOf('screen') > -1;
    }).length > 0;

    if (!isScreenShared) return null;

    return (
        <ParticipantControl onClick={onClick}>
            {<ScreenShare/>}
        </ParticipantControl>
    );
};

export default ScreenShareIndicator;