import {ensureMediaPermissions} from "../../../utils";
import {localDevicesFetched, setLocalTrack} from "../actions";

export const getDevices = async (dispatch) => {
    await ensureMediaPermissions()
    const devices = await navigator.mediaDevices.enumerateDevices();
    dispatch(localDevicesFetched(devices));
}

export const getScreenShareStream = async (dispatch) => {
    if (!navigator?.mediaDevices.getDisplayMedia)
        return Promise.reject("Screen share not supported")

    return navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
              frameRate: 10,
              height: 1080,
              width: 1920,
            },
          }).then((stream)=>{
              let avStream = {
                  id: stream.id,
                  name: stream.name || stream.label,
                  type: 'screen',
                  track: stream,
                  isEnabled: true
              };
              dispatch(setLocalTrack('screen', avStream));
              return stream;
    })
}


export const DEFAULT_AV_PARTICIPANT_STATE = {
    id: null,
    name: null,
    participantType: "default",
    status: "offline",
    isFacilitator: false,
    serviceId: null,
    audioTracks: [],
    videoTracks: [],
    handUp: false,
    hasMic: false,
    timeConnected: null,
    admissionStatus: null
}

export const DEFAULT_OFFLINE_AV_PARTICIPANT_STATE = {
    status: "offline",
    isFacilitator: false,
    audioTracks: [],
    videoTracks: [],
    participantType: null,
    serviceId: null,
    handUp: false,
    hasMic: false,
    timeConnected: null,
    admissionStatus: null
}