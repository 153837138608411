import {
    updateAVParticipant
} from "../actions";

export const makeVideoHandlers = ({dispatch, getState}) => {

    const onUpdateAVParticipants = (newAVParticipants) => {
        Object.keys(newAVParticipants).forEach((avParticipantID) => {
            dispatch(updateAVParticipant(newAVParticipants[avParticipantID]));
        })
    };

    return {
        onUpdateAVParticipants
    };
};

