import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import audioVideoReducer from "./audioVideo/reducer";
import videoMiddleware from "./audioVideo/middleware";
import appReducer from "./app/reducer";
import appMiddleware from "./app/middleware";
import twilioMiddleware from "./twilio/middleware";
import twilioReducer from "./twilio/reducer";
import socketReducer from "./socket/reducer";
import socketMiddleware from "./socket/middleware";
import bookingReducer from "./booking/reducer";
import bookingMiddleware from "./booking/middleware";
import remoteControlMiddleware from "./remoteControl/middleware";

const rootReducer = combineReducers({
  audioVideo: audioVideoReducer,
  app: appReducer,
  twilio: twilioReducer,
  socket: socketReducer,
  bookingDetails: bookingReducer
});

const featureMiddleware = [
  videoMiddleware,
  appMiddleware,
  twilioMiddleware,
  socketMiddleware,
  bookingMiddleware,
  remoteControlMiddleware
];

const coreMiddleware = [];

const middleware = [...featureMiddleware, ...coreMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

window.store = store;

export default store;
