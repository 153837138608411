import React from 'react';
import {Videocam, VideocamOff} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {ParticipantControl} from "../../../layout/layout";

const ParticipantVideoIndicator = ({id, onClick}) => {
    const videos = useSelector(store => {
        if (id === 'local')
            return [store.audioVideo.localTracks.video];
        return store.audioVideo.remoteVideoTracks[id];
    });

    const isVideoEnabled = videos && videos.filter(track => {
        if (!track) return false;
        let trackName = track.trackName ? track.trackName : track.name;
        return trackName.indexOf('screen') === -1;
    }).length > 0;

    return (
        <ParticipantControl onClick={onClick}>
            {!isVideoEnabled ? <VideocamOff /> : <Videocam />}
        </ParticipantControl>
    );
};

export default ParticipantVideoIndicator;