import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {Room} from "./Room";
import {useParams} from "react-router-dom";
import MenuBar from "./MenuBar/MenuBar";
import PostConsult from "./PostConsult/PostConsult";
import {setRoomState} from "../redux/audioVideo/actions";

export function MockConsult() {
    const dispatch = useDispatch();
    const roomState = useSelector(state => state.audioVideo.roomState);
    const roomActive = roomState === "connected" || roomState === "reconnecting";
    let {consult_token_id} = useParams();


    useEffect(() => {
        dispatch(setRoomState('connected'))
        dispatch({type: "MOCK_CONSULT", payload: consult_token_id})
    }, [dispatch, consult_token_id])

    const renderConsultState = () => {

        if (roomActive)
            return <Room/>

        return <PostConsult/>

    };

    return (
        <Container>
            <MenuBar/>
            {renderConsultState()}
        </Container>

    )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;