import React, {useCallback, useEffect} from "react";

import { styled } from "@material-ui/core/styles";
import LocalVideo from "./Participants/LocalVideo";
import {useSelector} from "react-redux";


const VideoContainer = styled("div")({
  display: "grid",
  padding: 0,
  gridTemplateRows: "1fr",
  gridTemplateColumns: "1fr",
  position: "relative",
  borderRadius: 8,
  overflow: 'hidden',
});

export default function LocalVideoPreview(props) {
  const [videoContainerWidth, setVideoContainerWidth] = React.useState(0);
  const [videoContainerHeight, setVideoContainerHeight] = React.useState(0);
  const videoTrack = useSelector(state => state.audioVideo.localTracks.video?.track)

  const updateDimensions = useCallback((track) => {
    let _width = "50%";
    let _height = "50%";

    if (track) {
      let videoHeight = track.dimensions.height;
      let videoWidth = track.dimensions.width;

      if (videoHeight !== null && videoWidth !== null) {
        let requiredScaling = Math.min(props.maxHeight / videoHeight, props.maxWidth / videoWidth);
        _width = Math.floor(videoWidth * requiredScaling);
        _height = Math.floor(videoHeight * requiredScaling);
      }
    }

    setVideoContainerWidth(_width);
    setVideoContainerHeight(_height);

  }, [props.maxHeight, props.maxWidth]);

  useEffect(() => {
    updateDimensions(videoTrack);

    if (videoTrack) {
      videoTrack.on("started", updateDimensions);
      videoTrack.on("dimensionsChanged", updateDimensions);

      return () => {
        videoTrack.off('started', updateDimensions);
        videoTrack.off('dimensionsChanged', updateDimensions);
      }
    }
  }, [videoTrack, updateDimensions]);

  return (
    <VideoContainer style={{ width: videoContainerWidth + "px", height: videoContainerHeight + "px" }}>
      <LocalVideo disablePinning/>
      {props.children}
    </VideoContainer>);
}
