import {isMobile, removeUndefineds} from "../utils";

export default function useConnectionOptions() {

    let connectionOptions = {
        automaticSubscription: false,
        bandwidthProfile: {
            video: {
                mode: 'presentation',
                renderDimensions: {
                    low: {width: 160, height: 90}, // low
                    standard: {width: 352, height: 288}, // cif
                    high: {width: 1280, height: 720}, // 720p
                },
                maxTracks: 3,
            },
        },
        // dominantSpeaker: true,
        // dominantSpeakerPriority: 'low',
        networkQuality: {local: 1, remote: 1},
        // Comment this line if you are playing music.
        maxAudioBitrate: 16000,
        // VP8 simulcast enables the media server in a Small Group or Group Room
        // to adapt your encoded video quality for each RemoteParticipant based on
        // their individual bandwidth constraints. Simulcast should be disabled if
        // you are using Peer-to-Peer Rooms.
        region: 'gll',
        preferredVideoCodecs: [{codec: 'VP8', simulcast: true}],
        logLevels: {
            media: 'debug'
        }
    };

    // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
    if (isMobile) {
        connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
    }

    // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
    // when no value is passed for an option. It will throw an error when 'undefined' is passed.
    return removeUndefineds(connectionOptions);
}