import React from "react";

import {useSelector} from "react-redux";
import {ParticipantLabel, ParticipantLayoutDiv} from "../../layout/layout";
import ParticipantFooter from "./ParticipantFooter";
import ParticipantVideo from "./ParticipantVideo";
import Avatar from "./Avatar";
import {PARTICIPANT_OFFLINE_STATUSES} from "../../consts";

function MainParticipant() {
    const video = useSelector(state => state.audioVideo.localTracks?.video)
    const screen = useSelector(state => state.audioVideo.localTracks?.screen)
    let localParticipant = {id: 'local', trackId: screen ? screen?.id : video?.id};

    const participants = useSelector(state => state.audioVideo.participants)
    const participantVideos = useSelector(state => state.audioVideo.remoteVideoTracks);

    const dominantSpeaker = useSelector(state => state.audioVideo.dominantSpeaker)
    const pinnedParticipant = useSelector(state => state.audioVideo.pinnedParticipant)

    const hasMicParticipantId = Object.keys(participants).find(participant_id => participants[participant_id].hasMic)
    const facilitatorId = Object.keys(participants).find(participant_id => participants[participant_id].isFacilitator)

    let hasMicParticipant = (hasMicParticipantId && participants[hasMicParticipantId]) ? participants[hasMicParticipantId] : null;
    let facilitator = facilitatorId ? participants[facilitatorId] : null;

    const micParticipantOnline = hasMicParticipant && PARTICIPANT_OFFLINE_STATUSES.indexOf(hasMicParticipant.status) < 0;
    const facilitatorOnline = facilitator && PARTICIPANT_OFFLINE_STATUSES.indexOf(facilitator.status) < 0;

    const onlineMicParticipant = micParticipantOnline ? hasMicParticipant : null;
    const onlineFacilitator = facilitatorOnline ? facilitator : null;

    const participant = pinnedParticipant || onlineMicParticipant || dominantSpeaker || onlineFacilitator || localParticipant;

    const isLocalParticipant = participant.id === 'local';
    const invalidLocalParticipant = isLocalParticipant && (!screen && !video);

    const invalidParticipant = !participant || !participant.id || invalidLocalParticipant;
    const participantName = isLocalParticipant ? "You" : participants[participant.id]?.name;

    let mainParticipantVideos = participantVideos[participant.id] || [];
    let isScreenTrack = false;

    if (isLocalParticipant){
        isScreenTrack = screen && (!participant.trackId || participant.trackId === screen?.id)
        mainParticipantVideos = isScreenTrack ? [screen] : (video ? [video] : [])
    }

    let videoToRender;
    if (participant.trackId)
        videoToRender = mainParticipantVideos.find(track => track.id === participant.trackId)
    else
        videoToRender = mainParticipantVideos.find(track => track.name === "screen")

    if (!videoToRender && mainParticipantVideos.length > 0) videoToRender = mainParticipantVideos[0]

    if (invalidParticipant || !videoToRender)
        return (
            <ParticipantLayoutDiv id={"mainVideo"}>
                <ParticipantLabel>{participantName}</ParticipantLabel>
                <Avatar name={participantName}/>
                <ParticipantFooter id={participant?.id}/>
            </ParticipantLayoutDiv>
        );

    return (
        <ParticipantLayoutDiv id={"mainVideo"}>
            <ParticipantLabel>{participantName}</ParticipantLabel>
            <ParticipantVideo track={videoToRender} isLocal={!isScreenTrack && isLocalParticipant}/>
            <ParticipantFooter id={participant.id}/>
        </ParticipantLayoutDiv>
    )
}

export default React.memo(MainParticipant)
