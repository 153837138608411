import React, {} from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from "react-redux";
import {toggleLocalAudio} from "../../redux/audioVideo/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function ToggleAudioButton(props) {
  const classes = useStyles();
  const isFacilitator = useSelector(state => state.audioVideo?.localState?.isFacilitator);
  const isAudioEnabled = useSelector(state => state.audioVideo?.localTracks?.audio?.isEnabled);
  const dispatch = useDispatch();
  const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);

  const toggleAudioEnabled = () => dispatch(toggleLocalAudio());

  if (!hasMic && !isFacilitator) return null;

  return (
    <Tooltip
      {...(props.hidden ? { open: true } : {})}
      title={isAudioEnabled ? 'Mute Audio' : 'Unmute Audio'}
      placement={props.hidden ? 'left' : 'top'}
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={classes.fab} onClick={toggleAudioEnabled} disabled={props.disabled} data-cy-audio-toggle>
        {isAudioEnabled ? <Mic /> : <MicOff />}
      </Fab>
    </Tooltip>
  );
}

ToggleAudioButton.defaultProps = {
  hidden: false,
};
