import {
    INIT_VIDEO,
    LOCAL_DEVICES_FETCHED,
    MUTE_LOCAL_AUDIO,
    MUTE_LOCAL_SCREEN,
    MUTE_LOCAL_VIDEO, muteLocalAudio, muteLocalScreen, muteLocalVideo,
    SET_HAS_MIC,
    setLocalDevices,
    toggleLocalAudio,
    toggleLocalVideo,
    toggleScreenShare,
    TURN_OFF_LOCAL_DEVICES,
    UPDATE_AV_PARTICIPANTS,
} from '../actions';
import {getDevices} from "./helpers";
import {makeVideoHandlers} from "./handlers";
import {sendMessage, unmuteRequest} from "../../booking/actions";


const videoMiddleware = ({dispatch, getState}) => {
    const fetchDevices = () => getDevices(dispatch);
    const {
        onUpdateAVParticipants
    } = makeVideoHandlers({dispatch, getState})

    return (next) => (action) => {

        // All switch cases are scoped with {} to allow variable names to be redeclared in the new scope
        switch (action.type) {
            case INIT_VIDEO: {
                fetchDevices()
                navigator.mediaDevices.addEventListener('devicechange', fetchDevices);
                break;
            }
            case LOCAL_DEVICES_FETCHED: {
                dispatch(setLocalDevices(action.payload));
                break;
            }
            case UPDATE_AV_PARTICIPANTS: {
                onUpdateAVParticipants(action.payload);
                break;
            }
            case SET_HAS_MIC: {
                dispatch(sendMessage({ action: 'websocket-api', request: 'set-mic-participant',
                    setHasMic: action.payload.hasMic, participantId: action.payload.participantId }));
                break;
            }
            case MUTE_LOCAL_AUDIO: {
                const isAudioEnabled = getState().audioVideo?.localTracks?.audio?.isEnabled;
                if (isAudioEnabled)
                    dispatch(toggleLocalAudio());
                break;
            }
            case MUTE_LOCAL_VIDEO: {
                let isVideoEnabled = getState().audioVideo?.localTracks?.video?.isEnabled;
                if (isVideoEnabled)
                    dispatch(toggleLocalVideo());
                break;
            }
            case MUTE_LOCAL_SCREEN: {
                let screen = getState().audioVideo.localTracks?.screen;
                    if (!!screen)
                        dispatch(toggleScreenShare());
                break;
            }
            case TURN_OFF_LOCAL_DEVICES: {
                dispatch(muteLocalAudio());
                dispatch(muteLocalVideo());
                dispatch(muteLocalScreen());
            }
            default:
                break;
        }
        return next(action);
    };
};

export default videoMiddleware;
