import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch, useSelector} from "react-redux";
import {hasMicRequest, unmuteRequest} from "../redux/booking/actions";
import {toggleLocalAudio, toggleLocalVideo} from "../redux/audioVideo/actions";
import Button from "@material-ui/core/Button";
import {DialogActions} from "@material-ui/core";

const HasMicRequestDialog = () => {
    const hasMic = useSelector(state => state.audioVideo.localState?.hasMic);
    const request = useSelector((state) => state.bookingDetails?.hasMicRequest);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(hasMicRequest(false))
    };

    const handleAction = (stream) => {
        if (stream === "audio"){
            dispatch(toggleLocalAudio())
        }
        else {
            dispatch(toggleLocalAudio())
            dispatch(toggleLocalVideo())
        }
        onClose();
    };

    return (
        <Dialog open={hasMic && request} onClick={() => {onClose()}} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Request received from Facilitator</DialogTitle>
            <DialogContent>
                <p>You have been passed the Mic</p>
                <p>Please turn your audio and/or your video on</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAction('audio')} color="secondary">
                    TURN ON AUDIO
                </Button>
                <Button onClick={() => handleAction('audioVideo')} color="primary" autoFocus>
                    TURN ON AUDIO AND VIDEO
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HasMicRequestDialog;
