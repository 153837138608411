import {DEFAULT_AV_PARTICIPANT_STATE} from "../../audioVideo/middleware/helpers";
import {updateAVParticipants} from "../../audioVideo/actions";
import portraitSample from "../../../assets/portraitSample.mp4";
import landscapeSample from "../../../assets/landscapeSample.mp4";

import {setConsultState} from "../actions";

const appMiddleware = ({dispatch, getState}) => {

    const createAudioTrack = (id, orientation) => {
        return {
            id,
            kind: 'audio',
            type: "mock",
            src: orientation === 'landscape' ? landscapeSample : portraitSample
        }
    };

    const createVideoTrack = (id, orientation) => {
        return {
            id,
            kind: 'video',
            type: "mock",
            src: orientation === 'landscape' ? landscapeSample : portraitSample
        }
    };

    const createAVParticipant = (participant) => {
        let orientation = Math.random() < 0.5 ? "landscape" : "portrait"
        return {
            ...DEFAULT_AV_PARTICIPANT_STATE,
            id: `User ${participant}`,
            name: `User ${participant}`,
            participantType: "mock",
            status: "online",
            serviceId: `User ${participant}`,
            audioTracks: [createAudioTrack(`User ${participant}`, orientation)],
            videoTracks: [createVideoTrack(`User ${participant}`, orientation)]
        }
    };

    const addConsultParticipants = (numberOfParticipants) => {
        let participants = {};
        for (let i = 0; i < numberOfParticipants; i++) {
            let newParticipant = createAVParticipant(i);
            participants[newParticipant.id] = newParticipant
        }
        dispatch(updateAVParticipants(participants));
    };

    return (next) => (action) => {
        switch (action.type) {
            case "MOCK_CONSULT":{
                dispatch(setConsultState('in-consult'))
                addConsultParticipants(action.payload);
                break;
            }
            default:
                break;
        }
        return next(action);
    };
};

export default appMiddleware;
