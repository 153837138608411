export const INIT_VIDEO = 'INIT_VIDEO';
export const LOCAL_DEVICES_FETCHED = 'LOCAL_DEVICES_FETCHED';
export const SET_LOCAL_DEVICES = 'SET_LOCAL_DEVICES';
export const SET_LOCAL_TRACKS = 'SET_LOCAL_TRACKS';
export const SET_AUDIO_OUTPUT_DEVICE = "SET_AUDIO_OUTPUT_DEVICE";
export const TOGGLE_LOCAL_VIDEO = 'TOGGLE_LOCAL_VIDEO';
export const TOGGLE_LOCAL_AUDIO = 'TOGGLE_LOCAL_AUDIO';
export const TOGGLE_SCREEN_SHARE = "TOGGLE_SCREEN_SHARE";
export const UPDATE_PARTICIPANT_STATUS = "UPDATE_PARTICIPANT_STATUS";

export const FLIP_CAMERA = "FLIP_CAMERA";
export const SET_VIDEO_DEVICE = "SET_VIDEO_DEVICE";
export const SET_AUDIO_INPUT_DEVICE = "SET_AUDIO_INPUT_DEVICE";
export const END_CALL = 'END_CALL';
export const UPDATE_AV_PARTICIPANTS = "UPDATE_AV_PARTICIPANTS";
export const SET_AV_PARTICIPANTS = "SET_AV_PARTICIPANTS";
export const UPDATE_AV_PARTICIPANT = "UPDATE_AV_PARTICIPANT";
export const SET_AV_PARTICIPANTS_AUDIO_TRACKS = "SET_AV_PARTICIPANTS_AUDIO_TRACKS";
export const SET_AV_PARTICIPANTS_VIDEO_TRACKS = "SET_AV_PARTICIPANTS_VIDEO_TRACKS";
export const SET_LOCAL_TRACK = "SET_LOCAL_TRACK";
export const MUTE_LOCAL_AUDIO = "MUTE_LOCAL_AUDIO";
export const MUTE_LOCAL_VIDEO = "MUTE_LOCAL_VIDEO";
export const MUTE_LOCAL_SCREEN = "MUTE_LOCAL_SCREEN";
export const TURN_OFF_LOCAL_DEVICES = "TURN_OFF_LOCAL_DEVICES";

export const REMOTE_CONTROL_INSTRUCTION = 'REMOTE_CONTROL_INSTRUCTION';
export const MUTE_EVERYBODY_IN_CALL = 'MUTE_EVERYBODY_IN_CALL';

export const SET_ROOM_STATE = 'SET_ROOM_STATE';
export const UPDATE_LOCAL_PARTICIPANT_STATE = "UPDATE_LOCAL_PARTICIPANT_STATE";
export const SET_PINNED_PARTICIPANT = "SET_PINNED_PARTICIPANT";
export const SET_DOMINANT_SPEAKER = "SET_DOMINANT_SPEAKER";
export const SET_HAS_MIC = "SET_HAS_MIC";

export const initVideo = () => ({type: INIT_VIDEO})
export const localDevicesFetched = (payload) => ({type: LOCAL_DEVICES_FETCHED, payload})
export const setLocalDevices = (payload) => ({type: SET_LOCAL_DEVICES, payload})
export const setLocalTracks = (payload) => ({type: SET_LOCAL_TRACKS, payload})
export const toggleLocalVideo = () => ({type: TOGGLE_LOCAL_VIDEO})
export const toggleLocalAudio = () => ({type: TOGGLE_LOCAL_AUDIO})
export const toggleScreenShare = () => ({type: TOGGLE_SCREEN_SHARE})
export const flipCamera = (newFacingMode) => ({type: FLIP_CAMERA, payload: newFacingMode})
export const setVideoDevice = (device) => ({type: SET_VIDEO_DEVICE, payload: device})
export const setAudioInputDevice = (device) => ({type: SET_AUDIO_INPUT_DEVICE, payload: device})
export const setAudioOutputDevice = (device) => ({type: SET_AUDIO_OUTPUT_DEVICE, payload: device})
export const endCall = () => ({type: END_CALL})
export const setRoomState = (state) => ({type: SET_ROOM_STATE, payload: state})

export const updateAVParticipants = (newAVParticipants) => ({type: UPDATE_AV_PARTICIPANTS, payload: newAVParticipants})

export const setAVParticipants = (avParticipants) => ({type: SET_AV_PARTICIPANTS, payload: avParticipants});
export const setAVParticipantsAudioTracks = (avParticipants) => ({type: SET_AV_PARTICIPANTS_AUDIO_TRACKS, payload: avParticipants});
export const setAVParticipantsVideoTracks = (avParticipants) => ({type: SET_AV_PARTICIPANTS_VIDEO_TRACKS, payload: avParticipants});
export const updateAVParticipant = (avParticipant) => ({type: UPDATE_AV_PARTICIPANT, payload: avParticipant})
export const setLocalTrack = (trackKind, track) => ({type: SET_LOCAL_TRACK, payload: {trackKind, track}})
export const updateParticipantStatus = (participantStatus) => ({type: UPDATE_PARTICIPANT_STATUS, payload: participantStatus})
export const updateLocalParticipantStatus = (participantState) => ({type: UPDATE_LOCAL_PARTICIPANT_STATE, payload: participantState})
export const setPinnedParticipant = (trackDetails) => ({type: SET_PINNED_PARTICIPANT, payload: trackDetails})
export const setDominantSpeaker = (dominantSpeaker) => ({type: SET_DOMINANT_SPEAKER, payload: dominantSpeaker})
export const setParticipantHasMic = (id, hasMic) => ({type: SET_HAS_MIC, payload: {participantId: id, hasMic}});
export const muteLocalAudio = () => ({type: MUTE_LOCAL_AUDIO})
export const muteLocalVideo = () => ({type: MUTE_LOCAL_VIDEO})
export const muteLocalScreen = () => ({type: MUTE_LOCAL_SCREEN})
export const turnOffLocalDevices = () => ({type: TURN_OFF_LOCAL_DEVICES})